import { RouteRecordRaw } from 'vue-router'

export const routes: Array<RouteRecordRaw> = [
  {
    meta: { requiresNowStore: true, title: '結帳紀錄', permission: 'IncomeStatement.Succeed' },
    path: 'succeed',
    name: 'succeed',
    component: () =>
      import(/* webpackChunkName: "succeed" */ '../../views/succeed/main.vue')
  }
]
