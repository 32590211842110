import { emitter } from './events'
import { notificationOptions } from '@/type'

export default {
  install: (app: any, options: any) => {
    const notify = (
      notificationOptions: notificationOptions,
      timeout: number
    ) => {
      emitter.emit('addNotify', {
        notificationOptions: notificationOptions,
        timeout: timeout
      })
    }
    notify.close = function (): void {
      emitter.emit('closeNotify')
    }
    app.provide('notify', notify)
  }
}
