import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { dynamicallyRouter, devRouter } from './loader'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/:catchAll(.*)',
    meta: { title: '找不到頁面' },
    name: 'notFound',
    component: () =>
      import(/* webpackChunkName: "site" */ '../views/status/notFound.vue')
  },
  {
    path: '/login',
    meta: { guestOnly: true, title: '登入頁' },
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "site" */ '../views/site/login.vue')
  },
  {
    path: '/loginId',
    meta: { title: '轉跳頁' },
    name: 'loginId',
    component: () =>
      import(/* webpackChunkName: "site" */ '../views/site/loginId.vue')
  },
  {
    path: '/googleLoginCallback',
    meta: { title: '轉跳頁' },
    name: 'googleLoginCallback',
    component: () =>
      import(
        /* webpackChunkName: "site" */ '../views/site/googleLoginCallback.vue'
      )
  },
  {
    path: '/google2Fa',
    meta: { title: '兩步驟驗證碼' },
    name: 'google2Fa',
    component: () =>
      import(/* webpackChunkName: "site" */ '../views/site/google2Fa.vue')
  },
  {
    path: '/download',
    meta: { title: '檔案下載' },
    name: 'download',
    component: () =>
      import(/* webpackChunkName: "site" */ '../views/site/download.vue')
  },
  {
    path: '/reset',
    name: 'reset',
    component: () =>
      import(/* webpackChunkName: "site" */ '../views/site/reset.vue')
  },
  {
    path: '/',
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "main" */ '../views/layouts/main.vue'),
    children: [
      {
        path: '/status',
        name: 'status',
        component: () =>
          import(/* webpackChunkName: "site" */ '../views/status/common.vue')
      },
      {
        path: '/refresh',
        name: 'refresh',
        component: () =>
          import(/* webpackChunkName: "site" */ '../views/layouts/refresh.vue')
      },
      {
        path: '',
        meta: { title: '目前現況', requiresNowStore: true, permission: 'IncomeStatement.Business' },
        name: 'index',
        component: () =>
          import(/* webpackChunkName: "main" */ '../views/site/index.vue')
      },
      {
        path: 'nowStoreSelector',
        meta: { title: '選擇門市' },
        name: 'nowStoreSelector',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "main" */ '../views/layouts/nowStoreSelector.vue'
          )
      },
      ...devRouter,
      ...dynamicallyRouter
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
