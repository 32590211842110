import mitt from 'mitt'
import { modalOptions } from '@/type'
type EventType = {
  addNotify: unknown
  // add: NotificationOptions
  closeNotify: unknown

  addModal: modalOptions
  showModal: unknown
  closeModal: unknown

  startPending: unknown
  donePending: unknown
}

export const emitter = mitt<EventType>()
