import { RouteRecordRaw } from 'vue-router'

export const routes: Array<RouteRecordRaw> = [
  {
    meta: { requiresNowStore: true, title: '門市管理', adminOnly: true },
    path: 'store',
    component: () =>
      import(/* webpackChunkName: "store" */ '../../views/store/main.vue'),
    children: [
      {
        path: 'info',
        name: 'storeInfo',
        component: () =>
          import(
            /* webpackChunkName: "store" */ '../../views/store/info/main.vue'
          )
      },
      {
        path: 'marketing',
        name: 'storeMarketing',
        component: () =>
          import(
            /* webpackChunkName: "store" */ '../../views/store/marketing/main.vue'
          ),
        children: [
          {
            path: 'memberLogin',
            name: 'storeMarketing_memberLogin',
            component: () =>
              import(
                /* webpackChunkName: "store" */ '../../views/store/marketing/memberLogin/main.vue'
              )
          }
        ]
      },
      {
        path: 'product',
        name: 'storeProduct',
        component: () =>
          import(
            /* webpackChunkName: "store" */ '../../views/store/product/main.vue'
          ),
        children: [
          {
            path: 'list',
            name: 'storeProduct_list',
            component: () =>
              import(
                /* webpackChunkName: "store" */ '../../views/store/product/list/main.vue'
              )
          },
          {
            path: 'sort',
            name: 'storeProduct_sort',
            component: () =>
              import(
                /* webpackChunkName: "store" */ '../../views/store/product/sort/main.vue'
              )
          }
        ]
      }
    ]
  }
]
