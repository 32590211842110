import { RouteRecordRaw } from 'vue-router'

export const routes: Array<RouteRecordRaw> = [
  {
    meta: { requiresNowStore: true, title: '打卡紀錄', permission: 'IncomeStatement.ClockRecords' },
    path: 'clock',
    component: () =>
      import(/* webpackChunkName: "clock" */ '../../views/clock/main.vue'),
    children: [
      {
        path: 'records',
        name: 'clockRecords',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "clock" */ '../../views/clock/records/main.vue'
          )
      },
      {
        path: 'personal/:employeeNo',
        name: 'clockPersonal',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "clock" */ '../../views/clock/personal/main.vue'
          )
      }
    ]
  }
]
