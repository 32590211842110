import { RouteRecordRaw } from 'vue-router'

export const routes: Array<RouteRecordRaw> = [
  {
    meta: { requiresNowStore: true, title: '歷程紀錄' },
    path: 'history',
    name: 'history',
    component: () =>
      import(/* webpackChunkName: "history" */ '../../views/history/main.vue')
  }
]
