import { RouteRecordRaw } from 'vue-router'

export const routes: Array<RouteRecordRaw> = [
  {
    meta: { requiresNowStore: true, title: '訂金紀錄', permission: 'IncomeStatement.Pre' },
    path: 'pre',
    name: 'pre',
    component: () =>
      import(/* webpackChunkName: "pre" */ '../../views/pre/main.vue')
  }
]
