import { RouteRecordRaw } from 'vue-router'

export const routes: Array<RouteRecordRaw> = [
  {
    meta: { requiresNowStore: true, title: '庫存警示', permission: 'IncomeStatement.Oversold' },
    path: 'oversold',
    name: 'oversold',
    component: () =>
      import(/* webpackChunkName: "oversold" */ '../../views/oversold/main.vue')
  }
]
