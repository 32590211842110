import { RouteRecordRaw } from 'vue-router'

export const routes: Array<RouteRecordRaw> = [
  {
    meta: { requiresNowStore: true, title: '員工業績列表', permission: 'IncomeStatement.EmployeePerformance' },
    path: 'performance',
    name: 'performance',
    component: () =>
      import(/* webpackChunkName: "performance" */ '../../views/performance/main.vue')
  }
]
