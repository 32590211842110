import store from '@/store'
import router from '@/router'
import { userType } from '@/type'

export default {
  getUser,
  logout,
  isAdmin,
  isStoreAdmin,
  hasReadPermission,
  hasExportPermission
}

function getUser(): userType {
  const { userInfo } = store.getters
  return userInfo
}

function logout(): void {
  store.dispatch('authLogout').then(() => {
    router.push({
      name: 'login'
    })
  })
}

function isAdmin(): boolean {
  const { userInfo } = store.getters
  return userInfo.isAdmin ?? false
}

function isStoreAdmin(): boolean {
  const { userInfo, nowStore, stores } = store.getters

  const storeOwn = stores.find((store: any) => {
    return store.shopAdminId === userInfo.shopAdminId
  })

  const storeOwnId = storeOwn?.id ?? 'none'

  return userInfo.storeId === nowStore.id || storeOwnId === nowStore.id
}

function hasReadPermission(group: string): boolean {
  const { storeAdminPermissionKeys } = store.getters
  return storeAdminPermissionKeys.includes(group + '.Read')
}

function hasExportPermission(group: string): boolean {
  const { storeAdminPermissionKeys } = store.getters
  return storeAdminPermissionKeys.includes(group + '.Export')
}
