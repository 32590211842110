import { RouteRecordRaw } from 'vue-router'

const files = require.context('./dynamical', true, /\.ts$/)
const dynamicallyRouter: Array<RouteRecordRaw> = []
const devRouter: Array<RouteRecordRaw> = []

files.keys().forEach((key) => {
  const _key = key.split('.')[1].split('/')
  // TODO deep test
  const routes = require('./dynamical/' + _key[0] + _key[1]).routes
  routes.forEach((route: any) => dynamicallyRouter.push(route))
})

if (process.env.NODE_ENV !== 'production') {
  const files = require.context('./development', true, /\.ts$/)
  files.keys().forEach((key) => {
    const _key = key.split('.')[1].split('/')
    const routes = require('./development/' + _key[0] + _key[1]).routes
    routes.forEach((route: any) => devRouter.push(route))
  })
}

export { dynamicallyRouter, devRouter }
