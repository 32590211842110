import { RouteRecordRaw } from 'vue-router'

export const routes: Array<RouteRecordRaw> = [
  {
    meta: { requiresNowStore: true, title: '作廢紀錄', permission: 'IncomeStatement.VoidRecord' },
    path: 'void',
    name: 'void',
    component: () =>
      import(/* webpackChunkName: "void" */ '../../views/void/main.vue')
  }
]
