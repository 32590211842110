import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import store from '../store'

import { emitter } from '../plugins/events'
import helper from '@/helper'
import router from '@/router'

const baseUrl = process.env.VUE_APP_AXIOS_BASEURL

const axiosInstance = axios.create({
  baseURL: baseUrl,
  timeout: 50000,
  withCredentials: true
})

axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const userInfo = store.getters.userInfo

    if (userInfo && userInfo.token) {
      const token = userInfo.token
      if (token) {
        config.headers = {
          token: token
        }
      }
    }

    emitter.emit('startPending')

    return config
  },
  (error) => {
    console.log(error)
    emitter.emit('donePending')
    return Promise.resolve(error)
  }
)
// 響應攔截器
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    // if (response.headers['new-token'] !== undefined) {
    //   store.dispatch('resetToken', response.headers['new-token'])
    // }
    emitter.emit('donePending')
    // response.data = JSON.parse(response.data).data
    response.data = response.data.data
    return response
  },
  (error) => {
    emitter.emit('donePending')
    if (error.response) {
      switch (error.response.status) {
        case 401:
          error.response.errors = {
            code: '401',
            source: 'systemError',
            detail: '身份未認證'
          }
          helper.logout()
          break
        case 403:
          error.response.errors = {
            code: '403',
            source: 'systemError',
            detail: '權限不足'
          }
          break
        case 404:
          error.response.errors = {
            code: '404',
            source: 'systemError',
            detail: '系統找不到與要求相關聯的資源，因此該要求作業失敗'
          }
          break
        case 405:
          error.response.errors = {
            code: '405',
            source: 'systemError',
            detail: '不支援與要求相關聯的 HTTP 方法'
          }
          break
        case 422:
          if (
            error.response.data !== undefined &&
            error.response.data.errors.code === '10013'
          ) {
            error.response.errors = {
              code: '422',
              source: 'google2Fa',
              detail: 'google2Fa未驗證'
            }
            store.dispatch('emitNeedToGoogle2fa').then(() => {
              router.push({
                name: 'google2Fa'
              })
            })
          }
          break
        case 429:
          error.response.errors = {
            code: '429',
            source: 'systemError',
            detail: '太多的請求，請稍後'
          }
          break
        case 500:
          error.response.errors = {
            code: '500',
            source: 'systemError',
            detail: '發生內部錯誤，因此要求失敗'
          }
          break
        default:
          error.response.errors = error.response.data.errors
      }
    }
    return Promise.reject(error)
  }
)

export default axiosInstance
