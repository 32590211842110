import { RouteRecordRaw } from 'vue-router'

export const routes: Array<RouteRecordRaw> = [
  {
    meta: { requiresNowStore: true, title: '權限紀錄', permission: 'IncomeStatement.EmployeeRecord' },
    path: 'permission',
    name: 'permission',
    component: () =>
      import(/* webpackChunkName: "permission" */ '../../views/permission/main.vue')
  }
]
