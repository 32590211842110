import { RouteRecordRaw } from 'vue-router'

export const routes: Array<RouteRecordRaw> = [
  {
    meta: { requiresNowStore: true, title: '配置' },
    path: '',
    component: () =>
      import(/* webpackChunkName: "member" */ '../../views/member/main.vue'),
    children: [
      {
        path: 'bill',
        name: 'Bill',
        component: () =>
          import(
            /* webpackChunkName: "member" */ '../../views/member/bill/main.vue'
          )
      },
      {
        path: 'monitor',
        name: 'Monitor',
        component: () =>
          import(
            /* webpackChunkName: "member" */ '../../views/member/monitor/main.vue'
          )
      }
    ]
  }
]
