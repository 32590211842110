import { createStore } from 'vuex'
import axios from '../http'
import { AxiosRequestConfig } from 'axios'

export default createStore({
  state: {
    // 帳號資訊
    userInfo: JSON.parse(localStorage.getItem('userInfo') || '{}'),
    // 過期時間
    logoutTime: null as number | null,
    // 可看門市
    stores: [],
    // 下拉emails
    allowEmails: [],
    // 可用門市數量
    storeQuantity: 0,
    // 已用門市數量
    usedStoreQuantity: 0,
    // 可用裝置數量
    deviceQuantity: 0,
    // 已用裝置數量
    usedDeviceQuantity: 0,
    // 商店url
    shopsUrl: '',
    // 當前門市
    nowStore: {},
    // 通用變數
    globalVariable: {},
    // 是否已完成初始化
    status: false,
    alertPasswordChange: false,
    google: {},
    checkToken: localStorage.getItem('checkToken') || '',
    // 權限列表
    permissionLists: [],
    // 商店帳號權限
    storeAdminPermissionKeys: []
  },
  getters: {
    userInfo: (state) => state.userInfo,
    stores: (state) => state.stores,
    allowEmails: (state) => state.allowEmails,
    storeQuantity: (state) => state.storeQuantity,
    usedStoreQuantity: (state) => state.usedStoreQuantity,
    deviceQuantity: (state) => state.deviceQuantity,
    usedDeviceQuantity: (state) => state.usedDeviceQuantity,
    shopsUrl: (state) => state.shopsUrl,
    nowStore: (state) => state.nowStore,
    globalVariable: (state) => state.globalVariable,
    status: (state) => state.status,
    google: (state) => state.google,
    alertPasswordChange: (state) => state.alertPasswordChange,
    checkToken: (state) => state.checkToken,
    permissionLists: (state) => state.permissionLists,
    storeAdminPermissionKeys: (state) => state.storeAdminPermissionKeys,
    logoutTime: (state) => state.logoutTime
  },
  mutations: {
    setUser(state, payload) {
      localStorage.setItem('userInfo', JSON.stringify(payload))
      state.userInfo = payload
      axios.interceptors.request.use((config: AxiosRequestConfig) => {
        config.headers = {
          token: state.userInfo.token
        }
        return config
      })
    },
    setLogoutTime(state) {
      const logoutDate = new Date()
      logoutDate.setMinutes(logoutDate.getMinutes() + 30)
      logoutDate.setSeconds(logoutDate.getSeconds() + 1)
      console.log(logoutDate)
      state.logoutTime = logoutDate.getTime()
    },
    clearLogoutTime(state) {
      state.logoutTime = null
    },
    clearUser(state) {
      localStorage.removeItem('userInfo')
      state.userInfo = {}
    },
    setInit(state, payload) {
      state.stores = payload.stores
      state.allowEmails = payload.allowEmails
      state.storeQuantity = payload.storeQuantity
      state.usedStoreQuantity = payload.usedStoreQuantity
      state.deviceQuantity = payload.deviceQuantity
      state.usedDeviceQuantity = payload.usedDeviceQuantity
      state.shopsUrl = payload.shopsUrl
      state.globalVariable = payload.globalVariable
      state.userInfo.name = payload.auth.name
      state.userInfo.email = payload.auth.email
      state.status = true
      state.alertPasswordChange = payload.alertPasswordChange
      state.permissionLists = payload.permissionLists
    },
    clearInit(state) {
      state.stores = []
      state.allowEmails = []
      state.storeQuantity = 0
      state.usedStoreQuantity = 0
      state.deviceQuantity = 0
      state.usedDeviceQuantity = 0
      state.shopsUrl = ''
      state.nowStore = {}
      state.globalVariable = {}
      state.status = false
      state.alertPasswordChange = false
      state.permissionLists = []
      state.storeAdminPermissionKeys = []
    },
    setNowStore(state, payload) {
      state.nowStore = payload
    },
    setStoreAdminPermissionKeys(state, payload) {
      state.storeAdminPermissionKeys = payload
    },
    setGoogle(state, payload) {
      state.google = payload
    },
    clearNeedToGoogle2fa(state) {
      if (Object.keys(state.userInfo)) {
        if (
          Object.prototype.hasOwnProperty.call(
            state.userInfo,
            'needToGoogle2fa'
          )
        ) {
          delete state.userInfo.needToGoogle2fa
        }
        if (
          Object.prototype.hasOwnProperty.call(
            state.userInfo,
            'google2faSecret'
          )
        ) {
          delete state.userInfo.google2faSecret
        }
      }

      localStorage.setItem('userInfo', JSON.stringify(state.userInfo))
    },
    emitNeedToGoogle2fa(state) {
      if (Object.keys(state.userInfo)) {
        state.userInfo.needToGoogle2fa = true
        localStorage.setItem('userInfo', JSON.stringify(state.userInfo))
      }
    },
    resetToken(state, payload) {
      state.userInfo.token = payload
      localStorage.setItem('userInfo', JSON.stringify(state.userInfo))
    },
    setCheckToken(state, payload) {
      state.checkToken = payload
      localStorage.setItem('checkToken', payload)
    }
  },
  actions: {
    authLogin({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/login', payload)
          .then((res) => {
            commit('setLogoutTime')
            commit('setUser', res.data)
            resolve(res.data)
          })
          .catch((err) => reject(err))
      })
    },
    authLoginId({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/login/id', payload)
          .then((res) => {
            commit('setLogoutTime')
            commit('setUser', res.data)
            resolve(res.data)
          })
          .catch((err) => reject(err))
      })
    },
    setCheckToken({ commit }, payload) {
      commit('setCheckToken', payload)
    },
    googleLoginCallback({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/googleLoginCallback', payload)
          .then((res) => {
            commit('setLogoutTime')
            commit('setUser', res.data)
            resolve(res.data)
          })
          .catch((err) => reject(err))
      })
    },
    authLogout({ commit }) {
      commit('clearUser')
      commit('clearLogoutTime')
      commit('clearInit')
    },
    resetToken({ commit }, payload) {
      commit('resetToken', payload)
    },
    initSetup({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/init')
          .then((res) => {
            commit('setInit', res.data)
            resolve(res.data)
          })
          .catch((err) => reject(err))
      })
    },
    revokedToken({ commit }, storeId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/revoked/${storeId}`)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => reject(err))
      })
    },
    changeNowStore({ commit }, payload) {
      axios
        .get(`/storeAdmin/authPermissionKeys/${payload.id}`)
        .then((res) => {
          commit('setStoreAdminPermissionKeys', res.data)
        })
        .catch((err) => console.log(err))

      commit('setNowStore', payload)
    },
    revokedNeedToGoogle2fa({ commit }) {
      commit('clearNeedToGoogle2fa')
    },
    emitNeedToGoogle2fa({ commit }) {
      commit('emitNeedToGoogle2fa')
    }
  },
  modules: {}
})
