import { RouteRecordRaw } from 'vue-router'

export const routes: Array<RouteRecordRaw> = [
  {
    meta: { requiresNowStore: true, title: '小結 | 關帳', permission: 'IncomeStatement.Handover' },
    path: 'handover',
    name: 'handover',
    component: () =>
      import(/* webpackChunkName: "handover" */ '../../views/handover/main.vue')
  },
  {
    meta: { requiresNowStore: true, title: '小結 | 關帳 明細', permission: 'IncomeStatement.Handover' },
    path: 'handover/detail/:type',
    name: 'handoverDetail',
    component: () =>
      import(
        /* webpackChunkName: "handover" */ '../../views/handover/detail/main.vue'
      ),
    beforeEnter: (to) => {
      if (to.params.type !== 'handOver' && to.params.type !== 'checkOut') {
        return { name: 'handover' }
      }
    }
  }
]
