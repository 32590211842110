import { RouteRecordRaw } from 'vue-router'

export const routes: Array<RouteRecordRaw> = [
  {
    meta: { requiresNowStore: true, title: '臨時備用現金管理', permission: 'IncomeStatement.TemporaryReserve' },
    path: 'temporaryReserve',
    name: 'temporaryReserve',
    component: () =>
      import(
        /* webpackChunkName: "temporaryReserve" */ '../../views/temporaryReserve/main.vue'
      )
  }
]
