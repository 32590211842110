import { RouteRecordRaw } from 'vue-router'

export const routes: Array<RouteRecordRaw> = [
  {
    meta: { requiresNowStore: true, title: '發票紀錄', permission: 'IncomeStatement.Invoice' },
    path: 'invoice',
    component: () =>
      import(/* webpackChunkName: "invoice" */ '../../views/invoice/main.vue'),
    children: [
      {
        path: '',
        meta: { requiresNowStore: true, title: '使用紀錄' },
        name: 'invoice',
        component: () =>
          import(
            /* webpackChunkName: "invoice" */ '../../views/invoice/invoiceRecord/main.vue'
          )
      },
      {
        path: '',
        meta: { requiresNowStore: true, title: '取號紀錄' },
        name: 'storeInvoiceRecord',
        component: () =>
          import(
            /* webpackChunkName: "invoice" */ '@/views/invoice/storeInvoiceRecord/main.vue'
          )
      }
    ]
  }
]
