import { RouteRecordRaw } from 'vue-router'

export const routes: Array<RouteRecordRaw> = [
  {
    meta: { requiresNowStore: true, title: '營業報表', permission: 'IncomeStatement.Performance' },
    path: 'analysis',
    component: () =>
      import(
        /* webpackChunkName: "analysis" */ '../../views/analysis/main.vue'
      ),
    children: [
      {
        path: 'performance',
        name: 'analysisPerformance',
        component: () =>
          import(
            /* webpackChunkName: "analysis" */ '../../views/analysis/performance/main.vue'
          )
      },
      {
        path: 'discount',
        name: 'analysisDiscount',
        component: () =>
          import(
            /* webpackChunkName: "analysis" */ '../../views/analysis/discount/main.vue'
          )
      },
      {
        path: 'revenue',
        name: 'analysisRevenue',
        component: () =>
          import(
            /* webpackChunkName: "analysis" */ '../../views/analysis/revenue/main.vue'
          )
      },
      {
        path: 'storeOrder',
        name: 'analysisStoreOrder',
        component: () =>
          import(
            /* webpackChunkName: "analysis" */ '../../views/analysis/storeOrder/main.vue'
          )
      },
      {
        path: 'onlineOrder',
        name: 'analysisOnlineOrder',
        component: () =>
          import(
            /* webpackChunkName: "analysis" */ '../../views/analysis/onlineOrder/main.vue'
          )
      }
    ]
  }
]
