import { RouteRecordRaw } from 'vue-router'

export const routes: Array<RouteRecordRaw> = [
  {
    meta: {
      requiresNowStore: true,
      title: '寄存概況',
      permission: 'IncomeStatement.Partialpickup'
    },
    path: 'partialpickup',
    name: 'partialpickup',
    component: () =>
      import(
        /* webpackChunkName: "partialpickup" */ '../../views/partialpickup/main.vue'
      )
  },
  {
    meta: {
      requiresNowStore: true,
      title: '寄存紀錄',
      permission: 'IncomeStatement.Partialpickup'
    },
    path: 'partialpickupRecord',
    name: 'partialpickupRecord',
    component: () =>
      import(
        /* webpackChunkName: "partialpickup" */ '../../views/partialpickup/record/main.vue'
      )
  }
]
