import { RouteRecordRaw } from 'vue-router'

export const routes: Array<RouteRecordRaw> = [
  {
    path: 'auth',
    component: () =>
      import(/* webpackChunkName: "auth" */ '../../views/auth/main.vue'),
    children: [
      {
        path: 'store/create',
        meta: { title: '建立新門市' },
        name: 'authStoreCreate',
        component: () =>
          import(
            /* webpackChunkName: "auth" */ '../../views/auth/store/create/main.vue'
          )
      },
      {
        path: 'store',
        meta: { requiresNowStore: true, title: '帳號與權限', adminOnly: true },
        name: 'authStore',
        component: () =>
          import(
            /* webpackChunkName: "auth" */ '../../views/auth/store/main.vue'
          )
      },
      {
        path: 'pos',
        meta: { requiresNowStore: true, title: '帳號與權限', adminOnly: true },
        name: 'authPOS',
        component: () =>
          import(/* webpackChunkName: "auth" */ '../../views/auth/pos/main.vue')
      },
      {
        path: '',
        meta: { requiresNowStore: true, title: '帳號與權限', adminOnly: true },
        component: () =>
          import(
            /* webpackChunkName: "auth" */ '../../views/auth/permission/main.vue'
          ),
        children: [
          {
            path: 'permission',
            name: 'authPermission',
            component: () =>
              import(
                /* webpackChunkName: "auth" */ '../../views/auth/permission/list/main.vue'
              )
          },
          {
            path: 'group/:groupId',
            name: 'authGroup',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "auth" */ '../../views/auth/permission/group/main.vue'
              )
          },
          {
            path: 'group/create',
            name: 'authGroupCreate',
            component: () =>
              import(
                /* webpackChunkName: "auth" */ '../../views/auth/permission/group/create/main.vue'
              )
          },
          {
            path: 'employee/:employeeId',
            name: 'authEmployee',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "auth" */ '../../views/auth/permission/employee/main.vue'
              )
          },
          {
            path: 'employee/create',
            name: 'authEmployeeCreate',
            component: () =>
              import(
                /* webpackChunkName: "auth" */ '../../views/auth/permission/employee/create/main.vue'
              )
          },
          {
            path: 'resigned',
            name: 'authResigned',
            component: () =>
              import(
                /* webpackChunkName: "auth" */ '../../views/auth/permission/components/resigned.vue'
              )
          }
        ]
      }
    ]
  }
]
