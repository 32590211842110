import { emitter } from './events'
import { modalOptions } from '@/type'

export default {
  install: (app: any, options: any) => {
    const modal = (modalOptions: modalOptions) => {
      emitter.emit('addModal', modalOptions)
      const show = function (): void {
        emitter.emit('showModal')
      }
      const close = function (): void {
        emitter.emit('closeModal')
      }
      return { show, close }
    }
    app.provide('modal', modal)
  }
}
