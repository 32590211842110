import { RouteRecordRaw } from 'vue-router'

export const routes: Array<RouteRecordRaw> = [
  {
    meta: { requiresNowStore: true, title: '系統設置', adminOnly: true },
    path: 'system',
    component: () =>
      import(/* webpackChunkName: "system" */ '../../views/system/main.vue'),
    children: [
      {
        path: 'receipt',
        name: 'systemReceipt',
        component: () =>
          import(
            /* webpackChunkName: "system" */ '../../views/system/receipt/main.vue'
          )
      },
      {
        path: 'payment',
        name: 'systemPayment',
        component: () =>
          import(
            /* webpackChunkName: "system" */ '../../views/system/payManage/main.vue'
          )
      },
      {
        path: 'printer',
        name: 'systemPrinter',
        component: () =>
          import(
            /* webpackChunkName: "system" */ '../../views/system/printerManage/main.vue'
          )
      }
    ]
  }
]
