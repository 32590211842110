import { createApp, h } from 'vue'
import App from './App.vue'
import store from './store'
import axiosInstance from './http'
import router from './router'
import notification from './plugins/notification'
import modal from './plugins/modal'
import VCalendar from 'v-calendar'
// Global Properties
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: typeof axiosInstance
    $store: typeof store
    time: number
  }
}

// Register router Middleware
router.beforeEach(async (to, from, next) => {
  const { userInfo, nowStore, status } = store.getters
  if (to.name === 'google2Fa') {
    if (userInfo && userInfo.token && !userInfo.needToGoogle2fa) {
      router.push({
        name: 'index'
      })
    }
  } else if (userInfo && userInfo.token && !status) {
    await store.dispatch('initSetup').catch((rej) => {
      if (
        rej.response.errors.code === '422' &&
        rej.response.errors.source === 'google2Fa'
      ) {
      } else {
        store.dispatch('authLogout').then(() => {
          router.push({
            name: 'login'
          })
        })
      }
    })
  }

  if (to.meta.requiresAuth) {
    if (!Object.keys(userInfo).length) {
      next({
        name: 'login'
      })
      return
    } else {
      if (userInfo.needToGoogle2fa) {
        next({
          name: 'google2Fa'
        })
        return
      }
    }
  }

  if (to.meta.guestOnly) {
    if (Object.keys(userInfo).length) {
      next({
        name: 'index'
      })
      return
    }
  }

  if (to.meta.requiresNowStore) {
    if (!Object.keys(nowStore).length) {
      next({
        name: 'nowStoreSelector',
        params: {
          originFullPath: <any>to.fullPath,
          originRouteName: <any>to.name
        }
      })
      return
    }
  }

  if (to.meta.adminOnly) {
    if (!Object.keys(userInfo).length || !Object.keys(nowStore).length) {
      next({
        name: 'nowStoreSelector',
        params: {
          originFullPath: <any>to.fullPath,
          originRouteName: <any>to.name
        }
      })
      return
    }
    if (!userInfo.isAdmin && userInfo.shopAdminId !== nowStore.shopAdminId) {
      next({
        name: 'index'
      })
      return
    }
  }

  document.title = `${to.meta.title}`
  next()
})

router.afterEach(() => {
  store.commit('setLogoutTime')
})

const app = createApp({
  render: () => h(App)
})
app.use(store)
app.use(router)
app.use(notification, {})
app.use(modal, {})
app.use(VCalendar, {})
app.config.globalProperties.$axios = axiosInstance

// trigger outside click
app.directive('click-outside', {
  beforeMount(el, binding) {
    el.clickOutsideEvent = function (event: any) {
      if (!(el === event.target || el.contains(event.target))) {
        if (typeof binding.value === 'function') {
          binding.value()
        }
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})
app.mount('#app')

// const userInfo = store.getters.userInfo
// if (userInfo && userInfo.token) {
//   store.dispatch('initSetup')
// }
