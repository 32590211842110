import { RouteRecordRaw } from 'vue-router'

export const routes: Array<RouteRecordRaw> = [
  {
    meta: {
      requiresNowStore: true,
      title: '商品銷售',
      permission: 'IncomeStatement.ProductSales'
    },
    path: 'productSales',
    component: () =>
      import(
        /* webpackChunkName: "invoice" */ '../../views/productSales/main.vue'
      ),
    children: [
      {
        path: '',
        meta: { requiresNowStore: true, title: '品項概況' },
        name: 'productSales',
        component: () =>
          import(
            /* webpackChunkName: "productSales" */ '@/views/productSales/productRecord/main.vue'
          )
      },
      {
        path: '',
        meta: { requiresNowStore: true, title: '兌換概況' },
        name: 'exchangeSales',
        component: () =>
          import(
            /* webpackChunkName: "productSales" */ '@/views/productSales/exchangeRecord/main.vue'
          )
      }
    ]
  }
]
